import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";
import UAParser from "ua-parser-js";
const parser = new UAParser();
const browser = parser.setUA(navigator.userAgent).getBrowser().name;
Vue.config.productionTip = false;

const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "login-required",
    checkLoginIframe: ["Safari", "Firefox"].indexOf(browser) === -1,
  },
  config: {
    realm: process.env.VUE_APP_KC_REALM,
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: (keycloak) => {
    if (
      !keycloak.hasResourceRole("manager", process.env.VUE_APP_KC_CLIENT_ID) &&
      !keycloak.hasResourceRole("manager-dad", process.env.VUE_APP_KC_CLIENT_ID)
    ) {
      keycloak.logout();
    } else {
      tokenInterceptor(),
        new Vue({
          vuetify,
          router,
          render: (h) => h(App),
        }).$mount("#app");
    }
  },
});
